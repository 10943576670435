import React, { useContext, useEffect, useState } from "react";
import "./userPageSelect.scss";
import { Link } from "react-router-dom";
import UserPageCard from "../../components/userPageCard/UserPageCard";
import { makeRequest } from "../../axios";
import { AuthContext } from "../../context/authContext";
import { Pagination } from "antd";


function UserPageSelect() {
  const { currentUser } = useContext(AuthContext);
  const [hasPages, setHasPages] = useState(true);
  const [userPages, setUserPages] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5); // Default page size

  const getUserPages = async (page = 1, size = 5) => {
    try {
      const response = await makeRequest.get(`/users/pages/getOwnPages/${currentUser.id}`, {
        params: { page: page - 1, size },
      });
      console.log(response);
      setUserPages(response.data.pagesDetail);
      setTotalElements(response.data.totalElements || 0);
      setHasPages(response.data.pagesDetail.length > 0);
    } catch (error) {
      console.error(error);
      setHasPages(false);
    }
  };

  useEffect(() => {
    getUserPages(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const onPageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  return (
    <div className="selectUserPage">
      {hasPages ? (
        <div>
          <div className="head">
            <h1>Your Feeds</h1>
            <div>
               <button>
              <Link to="/exploreFeeds">Explore Pages!</Link>
            </button>
            <button>
              <Link to="/createFeeds">Create Page!</Link>
            </button>
            </div>
          </div>
          <hr />
          <div className="userPage">
            {userPages &&
              userPages.map((page, index) => (
                <div className="user" key={index}>
                  <UserPageCard page={page} getUserPages={getUserPages} />
                </div>
              ))}
          </div>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={totalElements}
            onChange={onPageChange}
            showSizeChanger
            pageSizeOptions={[2,4,6,8,10]}
          />
        </div>
      ) : (
        <div className="noPages">
          <h1>Oops, no feeds found.</h1>
          <div>
          <button>
            <Link to="/exploreFeeds">Explore Social Feeds!</Link>
          </button>
          <button>
            <Link to="/createFeeds">Create one for you!</Link>
          </button>
          </div>  
        </div>
      )}
    </div>
  );
}

export default UserPageSelect;
