// import "./profile.scss";
// import FacebookTwoToneIcon from "@mui/icons-material/FacebookTwoTone";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import InstagramIcon from "@mui/icons-material/Instagram";
// import PinterestIcon from "@mui/icons-material/Pinterest";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import PlaceIcon from "@mui/icons-material/Place";
// import LanguageIcon from "@mui/icons-material/Language";
// import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
// import Posts from "../../components/posts/Posts";
// import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
// import { makeRequest } from "../../axios";
// import { useLocation } from "react-router-dom";
// import { useContext, useState, useEffect } from "react";
// import { AuthContext } from "../../context/authContext";
// import Update from "../../components/update/Update";
// import ProfileTabs from "./ProfileTabs";
// import { blue } from '@mui/material/colors';
// import { red } from '@mui/material/colors';
// import CameraAltIcon from '@mui/icons-material/CameraAlt';
// import PersonIcon from '@mui/icons-material/Person';
// import GroupsIcon from '@mui/icons-material/Groups';
// import LoadingProfile from "./LoadingProfile";

// // Social Icons Component
// const SocialIcons = () => (
//   <div className="left">
//     <a href="http://facebook.com" target="_blank" rel="noopener noreferrer">
//       <FacebookTwoToneIcon sx={{ color: blue[700] }} fontSize="large" />
//     </a>
//     <a href="http://instagram.com" target="_blank" rel="noopener noreferrer">
//       <InstagramIcon sx={{ color: "#c13584" }} fontSize="large" />
//     </a>
//     <a href="http://twitter.com" target="_blank" rel="noopener noreferrer">
//       <TwitterIcon sx={{ color: blue[700] }} fontSize="large" />
//     </a>
//     <a href="http://pinterest.com" target="_blank" rel="noopener noreferrer">
//       <PinterestIcon sx={{ color: red[700] }} fontSize="large" />
//     </a>
//     <a href="http://linkedin.com" target="_blank" rel="noopener noreferrer">
//       <LinkedInIcon sx={{ color: blue[700] }} fontSize="large" />
//     </a>
//   </div>
// );

// // Profile Action Button Component
// const ProfileActionButton = ({ userId, currentUser, relationshipData, setOpenUpdate, handleFollow }) => {
//   if (userId === currentUser.id) {
//     return <button onClick={() => setOpenUpdate(true)}>Update profile</button>;
//   }

//   const isRequestPending = relationshipData?.friendListData.some(
//     (request) => request.userId === userId && request.status === "PENDING"
//   );

//   const isFriend = relationshipData?.friendListData.some(
//     (request) => request.userId === userId
//   );

//   return (
//     <button onClick={handleFollow}>
//       {isRequestPending
//         ? "Pending Request"
//         : isFriend
//           ? "Remove Friend"
//           : "Add Friend"}
//     </button>
//   );
// };

// const Profile = () => {
//   const [openUpdate, setOpenUpdate] = useState(false);
//   const [dataOfUser, setDataOfUser] = useState({});
//   const { currentUser } = useContext(AuthContext);
//   const [selectedTab, setSelectedTab] = useState("Posts");
//   const location = useLocation();
//   const userIdFromURL = parseInt(location.pathname.split("/")[2]);

//   const queryClient = useQueryClient();

//   const { isLoading, error, data } = useQuery(
//     ["user", userIdFromURL],
//     () => makeRequest.get(`/users/user/userAbout/${userIdFromURL}`).then((res) => {
//       setDataOfUser(res.data);
//       // console.log(res)
//       return res.data;
//     }),
//     {
//       enabled: !!userIdFromURL,  // Only fetch if userIdFromURL is not null or undefined
//       onError: (error) => {
//         console.error("Error fetching user data:", error);
//       },
//       refetchOnWindowFocus:false 
//     }
//   );

//   const { isLoading: rIsLoading, data: relationshipData } = useQuery(
//     ["relationship", currentUser.id],
//     () => makeRequest.get(`/users/friendrequest/getFriendsList/${currentUser.id}`).then((res) => res.data),
//     {
//       onError: (error) => {
//         console.error("Error fetching relationship data:", error);
//       }
//     }
//   );

//   const mutation = useMutation(
//     ({ isFollowing, friendRequestId }) => {
//       if (isFollowing) {
//         return makeRequest.put(`/users/friendrequest/rejectFriendRequest/${friendRequestId}`);
//       }

//       return makeRequest.post(`/users/friendrequest/sendFriendRequest/${currentUser.id}/${userIdFromURL}`);
//     },
//     {
//       onSuccess: () => {
//         queryClient.invalidateQueries(["relationship"]);
//       },
//       onError: (error) => {
//         console.error("Error handling friend request:", error);
//       }
//     }
//   );

//   const handleFollow = () => {
//     let friendRequestId;
//     const isFriend = relationshipData?.friendListData?.some(
//       (request) => {
//         if (request.userId === userIdFromURL) {
//           friendRequestId = request.friendRequestId;
//           return true;
//         }
//         return false;
//       }
//     );
//     console.log(isFriend, "innp")

//     if (isFriend) {
//       mutation.mutate({ isFollowing: true, friendRequestId });
//     } else {
//       mutation.mutate({ isFollowing: false });
//     }
//   };

//   if (isLoading) {
//     return <LoadingProfile />;
//   }

//   if (error) {
//     return <div>Error loading profile data.</div>;
//   }

//   return (
//     <div className="profile">
//       <div className="images">
//         <img src={data.userCoverProfileImage} alt="" className="cover" />
//         <img src={data.userProfileImage} alt="" className="profilePic" />
//       </div>
//       <div className="profileContainer">
//         <div className="uInfo">
//           {/* <SocialIcons /> */}
//           <div className="center">
//             <span>{data.userFullName.toUpperCase()}</span>
//             <p>{data.userEmail}</p>
//             <div className="info">
//               <div className="item">
//                 <PlaceIcon />
//                 <span>{data.userLocation}</span>
//               </div>
//               {/* Uncomment if needed
//               <div className="item">
//                 <LanguageIcon />
//                 <span>{data.website}</span>
//               </div>
//               */}
//             </div>
//             {rIsLoading ? (
//               "loading"
//             ) : (
//               <ProfileActionButton
//                 userId={userIdFromURL}
//                 currentUser={currentUser}
//                 relationshipData={relationshipData}
//                 handleFollow={handleFollow}
//                 setOpenUpdate={setOpenUpdate}
//               />
//             )}
//           </div>
//           {/* <div className="right">
//             <EmailOutlinedIcon sx={{ color: red[700] }} fontSize="large" />
//             <MoreVertIcon />
//           </div> */}
//         </div>
//         <div className="profileTab">
//           <div className="tabs">
//             <button
//               className={selectedTab === "Posts" ? "active" : ""}
//               onClick={() => setSelectedTab("Posts")}
//             >
//               <CameraAltIcon fontSize="medium" />
//               Posts
//             </button>
//             <div className="line"></div>
//             <button
//               className={selectedTab === "About" ? "active" : ""}
//               onClick={() => setSelectedTab("About")}
//             >
//               <PersonIcon fontSize="medium" />
//               About
//             </button>
//             <div className="line"></div>
//             <button
//               className={selectedTab === "Friends" ? "active" : ""}
//               onClick={() => setSelectedTab("Friends")}
//             >
//               <GroupsIcon fontSize="medium" />
//               Friends
//             </button>
//           </div>
//         </div>
//         <ProfileTabs selectedTab={selectedTab} userId={userIdFromURL} data={dataOfUser} />
//       </div>
//       {openUpdate && <Update setOpenUpdate={setOpenUpdate} user={data} />}
//     </div>
//   );
// };

// export default Profile;
import "./profile.scss";
import FacebookTwoToneIcon from "@mui/icons-material/FacebookTwoTone";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import TwitterIcon from "@mui/icons-material/Twitter";
import PlaceIcon from "@mui/icons-material/Place";
import LanguageIcon from "@mui/icons-material/Language";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Posts from "../../components/posts/Posts";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { useLocation, useParams } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../context/authContext";
import Update from "../../components/update/Update";
import ProfileTabs from "./ProfileTabs";
import { blue } from '@mui/material/colors';
import { red } from '@mui/material/colors';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import LoadingProfile from "./LoadingProfile";

// Social Icons Component
const SocialIcons = () => (
  <div className="left">
    <a href="http://facebook.com" target="_blank" rel="noopener noreferrer">
      <FacebookTwoToneIcon sx={{ color: blue[700] }} fontSize="large" />
    </a>
    <a href="http://instagram.com" target="_blank" rel="noopener noreferrer">
      <InstagramIcon sx={{ color: "#c13584" }} fontSize="large" />
    </a>
    <a href="http://twitter.com" target="_blank" rel="noopener noreferrer">
      <TwitterIcon sx={{ color: blue[700] }} fontSize="large" />
    </a>
    <a href="http://pinterest.com" target="_blank" rel="noopener noreferrer">
      <PinterestIcon sx={{ color: red[700] }} fontSize="large" />
    </a>
    <a href="http://linkedin.com" target="_blank" rel="noopener noreferrer">
      <LinkedInIcon sx={{ color: blue[700] }} fontSize="large" />
    </a>
  </div>
);

// Profile Action Button Component
const ProfileActionButton = ({ userId, currentUser, relationshipData, setOpenUpdate, handleFollow }) => {
  if (userId === currentUser.UUID) {
    return <button onClick={() => setOpenUpdate(true)}>Update profile</button>;
  }

  const isRequestPending = relationshipData?.friendListData.some(
    (request) => request.userId === userId && request.status === "PENDING"
  );

  const isFriend = relationshipData?.friendListData.some(
    (request) => request.userId === userId
  );

  return (
    <button onClick={handleFollow}>
      {isRequestPending
        ? "Pending Request"
        : isFriend
          ? "Remove Friend"
          : "Add Friend"}
    </button>
  );
};

const Profile = () => {
  const [openUpdate, setOpenUpdate] = useState(false);
  const [dataOfUser, setDataOfUser] = useState({});
  const { currentUser } = useContext(AuthContext);
  const [selectedTab, setSelectedTab] = useState("Posts");
  const location = useLocation();
  const userIdFromURL = parseInt(location.pathname.split("/")[2]);
   
  const params = useParams();  

  const queryClient = useQueryClient();

  const { isLoading, error, data } = useQuery(
    ["user", params.id],
    () => makeRequest.get(`/users/user/userAbout/${params.id}`).then((res) => {
      setDataOfUser(res.data);
      return res.data;
    }),
    {
      // enabled: !!userIdFromURL,  // Only fetch if userIdFromURL is not null or undefined
      onError: (error) => {
        console.error("Error fetching user data:", error);
      },
      refetchOnWindowFocus:false 
    }
  );

  const { isLoading: rIsLoading, data: relationshipData } = useQuery(
    ["relationship", currentUser.UUID],
    () => makeRequest.get(`/users/friendrequest/getFriendsList/${currentUser.UUID}`).then((res) => res.data),
    {
      onError: (error) => {
        console.error("Error fetching relationship data:", error);
      }
    }
  );

  const mutation = useMutation(
    ({ isFollowing, friendRequestId }) => {
      if (isFollowing) {
        return makeRequest.put(`/users/friendrequest/rejectFriendRequest/${friendRequestId}`);
      }

      // return makeRequest.post(`/users/friendrequest/sendFriendRequest/${currentUser.id}/${userIdFromURL}`);
      return makeRequest.post(`/users/friendrequest/sendFriendRequest/${currentUser.id}/${params.id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["relationship"]);
      },
      onError: (error) => {
        console.error("Error handling friend request:", error);
      }
    }
  );

  const handleFollow = () => {
    let friendRequestId;
    const isFriend = relationshipData?.friendListData?.some(
      (request) => {
        // if (request.userId === userIdFromURL) {
          if (request.userId === params.id) {
          friendRequestId = request.friendRequestId;
          return true;
        }
        return false;
      }
    );
    console.log(isFriend, "innp")

    if (isFriend) {
      mutation.mutate({ isFollowing: true, friendRequestId });
    } else {
      mutation.mutate({ isFollowing: false });
    }
  };

  if (isLoading) {
    return <LoadingProfile />;
  }

  if (error) {
    return <div>Error loading profile data.</div>;
  }

  return (
    <div className="profile">
      <div className="images">
        <img src={data.userCoverProfileImage} alt="" className="cover" />
      </div>
      <div className="profileContainer">
        <div className="uInfo">
          <div className="left1">
        <img src={data.userProfileImage} alt="" className="profilePic"/>
          </div>
          <div className="center">
            <span>{data.userFirstName.toUpperCase() +" "+ data.userLastName.toUpperCase()}</span>
            <p>{data.userEmail}</p>
            <div className="info">
              <div className="item">
                <PlaceIcon />
                <span>{data.userLocation}</span>
              </div>
              {/* Uncomment if needed
              <div className="item">
                <LanguageIcon />
                <span>{data.website}</span>
              </div>
              */}
            </div>
            {rIsLoading ? (
              "loading"
            ) : (
              <ProfileActionButton
                // userId={userIdFromURL}
                userId={params.id}
                currentUser={currentUser}
                relationshipData={relationshipData}
                handleFollow={handleFollow}
                setOpenUpdate={setOpenUpdate}
              />
            )}
          </div>
        </div>
        <div className="profileTab">
          <div className="tabs">
            <button
              className={selectedTab === "Posts" ? "active" : ""}
              onClick={() => setSelectedTab("Posts")}
            >
              <CameraAltIcon fontSize="medium" />
              Posts
            </button>
            <div className="line"></div>
            <button
              className={selectedTab === "About" ? "active" : ""}
              onClick={() => setSelectedTab("About")}
            >
              <PersonIcon fontSize="medium" />
              About
            </button>
            <div className="line"></div>
            <button
              className={selectedTab === "Friends" ? "active" : ""}
              onClick={() => setSelectedTab("Friends")}
            >
              <GroupsIcon fontSize="medium" />
              Friends
            </button>
          </div>
        </div>
        <ProfileTabs selectedTab={selectedTab} userId={params.id} data={dataOfUser} />
      </div>
      {openUpdate && <Update setOpenUpdate={setOpenUpdate} user={data} />}
    </div>
  );
};

export default Profile;
