import React, { useState } from "react";
import Posts from "../../components/posts/Posts";
import About from '../../components/about/About';
// import About from "../../components/about/About";
import FriendsListing from ".././friendsListing/FriendsListng"
// import "./profileTabs.scss"

const ProfileTabs = ({ userId, selectedTab ,data}) => {
console.log(selectedTab)

// console.log('this is data :',data)
  return (
    <div className="profileTabs">
    
      <div className="tabContent">
        {selectedTab === "Posts" && <Posts userId={userId} isTimeline={true}/>}
        {selectedTab === "About" && <About data={data}/>}
        {selectedTab === "Friends" && <FriendsListing userId={userId} />}
      </div>
    </div>
  );
};

export default ProfileTabs;