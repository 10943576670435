
import "./leftBar.scss";
import { AuthContext } from "../../context/authContext";
import { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/Home";
import GroupIcon from '@mui/icons-material/Group';
import MessageIcon from '@mui/icons-material/Message';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import GroupsIcon from '@mui/icons-material/Groups';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import StoreIcon from '@mui/icons-material/Store';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import CreateIcon from '@mui/icons-material/Create';
import { DarkModeContext } from "../../context/darkModeContext";
import AdsClickIcon from '@mui/icons-material/AdsClick';
import LogoutIcon from '@mui/icons-material/Logout';
import { Modal } from "antd";
import home from '../../assets/house.png'
import friends from '../../assets/friendship.png'
import bookmark from '../../assets/bookmark.png'
import message from '../../assets/comments.png'
import groups from '../../assets/youth.png'
import pencil from '../../assets/pencil.png'
import calendar from '../../assets/calendar.png'
import logoutImg from '../../assets/logout.png'
import trophy from '../../assets/trophy.png'

const LeftBar = () => {

  const { toggle, darkMode } = useContext(DarkModeContext);
  const { currentUser,logout } = useContext(AuthContext);
  const location = useLocation();
  const isMessagesPage = location.pathname === '/messages';
  // const activePath = location.pathname.includes;
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  let name = currentUser.name;
  let remianedname = name.slice(1)
  let firstletter = name.charAt(0).toUpperCase()
  let username = firstletter + remianedname

  let email = currentUser.email;
  let frontPart = email.slice(0,3);
  let lastPart = email.slice(-4);
  let emailWithMask = frontPart + "********" + lastPart;

  const handleLogout = () => {
    logout();
    navigate("/login");
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };


  return (
    <div className={`leftBar ${isMessagesPage ? "messagePage" : ""}`}>
      <div className="container">
        <div className={`menu ${isMessagesPage ? "messagePage" : ""}`}>
          <Link to={`/profile/${currentUser.UUID}`}>
            <div className="user">
              <img
                src={currentUser.profilePic}
                alt=""
              />
              <div className={isMessagesPage ? "messagePage" : ""}>
                <span style={{"fontWeight":600}}>{username}</span>
                {/* <p>{emailWithMask}</p> */}
              </div>
            </div>
          </Link>
          <hr className="hr-top" />
          <div className={`item ${location.pathname== "/" ? "active" : ""}`} >
            <Link to="/" className={`item-link ${location.pathname== "/" ? "active" : ""}`}>
              {/* <img src={Home} alt="" /> */}
              {/* <HomeOutlinedIcon className="icon" fontSize="medium" sx={{ color: !darkMode ? "#000":(location.pathname=="/")?"#000":"#fff"}}/> */}
              <img src={home} width="25px"/>
              <span className={isMessagesPage ? "messagePage" : ""}>Home</span>
            </Link>
          </div>

          <div className={`item ${location.pathname.includes("/connect") ? "active" : ""}`} >
            <Link to="/connect" className={`item-link ${location.pathname.includes("/connect") ? "active" : ""}`}>
              <img src={friends} alt="" />
              {/* <GroupIcon className="icon" fontSize="medium" sx={{color: !darkMode ? "#000":(darkMode && location.pathname=="/friends")?"#000":"#fff"}}/> */}
              <span className={isMessagesPage ? "messagePage" : ""}>Connect</span>
            </Link>
          </div>

          <div className={`item ${location.pathname.includes("/chat") ? "active" : ""}`}>
            <Link to="/chat"  className={`item-link ${location.pathname.includes("/chat") ? "active" : ""}`}>
              <img src={message} alt="" />
              {/* <MessageIcon className="icon" fontSize="medium" sx={{color: !darkMode ? "#000":(darkMode && location.pathname=="/messages")?"#000":"#fff"}}/> */}
              <span className={isMessagesPage ? "messagePage" : ""}>Chat</span>
            </Link>
          </div>

         

          <div className={`item ${location.pathname.includes("/organisation") ? "active" : ""}`}>
            <Link to="/organisation"  className={`item-link ${location.pathname.includes("/organisation") ? "active" : ""}`}>
              <img src={groups} alt="" />
              {/* <GroupsIcon className="icon" fontSize="medium" sx={{color: !darkMode ? "#000":(darkMode && location.pathname=="/groups")?"#000":"#fff"}}/> */}
              <span className={isMessagesPage ? "messagePage" : ""}>Organisation</span>
            </Link>
          </div>

          <div className={`item ${location.pathname.includes("/userfeeds") ? "active" : ""}`}>
            <Link to="/userfeeds"  className={`item-link ${location.pathname.includes("/userfeeds") ? "active" : ""}`}>
              <img src={pencil} alt="" />
              {/* <CreateIcon  className="icon" fontSize="medium" sx={{color: !darkMode ? "#000":(darkMode && location.pathname=="/userPage")?"#000":"#fff"}}/> */}
              <span className={isMessagesPage ? "messagePage" : ""}>Social Feeds</span>
            </Link>
          </div>


          <div className={`item ${location.pathname.includes("/saved") ? "active" : ""}`}>
            <Link to="/saved"  className={`item-link ${location.pathname.includes("/saved") ? "active" : ""}`}>
              <img src={bookmark} alt="" />
              {/* <BookmarkIcon className="icon" fontSize="medium" sx={{color: !darkMode ? "#000":(darkMode && location.pathname=="/saved")?"#000":"#fff"}}/> */}
              <span className={isMessagesPage ? "messagePage" : ""}>Saved</span>
            </Link>
          </div>

          <div className={`item ${location.pathname.includes("/events") ? "active" : ""}`}>
            <Link to="/events"  className={`item-link ${location.pathname.includes("/events") ? "active" : ""}`}>
              <img src={calendar} alt="" />
              {/* <CalendarMonthIcon className="icon" fontSize="medium" sx={{color: !darkMode ? "#000":(darkMode && location.pathname=="/events")?"#000":"#fff"}}/> */}
              <span className={isMessagesPage ? "messagePage" : ""}>Events</span>
            </Link>
          </div>
          <div className={`item ${location.pathname.includes("/competitions") ? "active" : ""}`}>
            <Link to="/competitions"  className={`item-link ${location.pathname.includes("/competitions") ? "active" : ""}`}>
              <img src={trophy} alt="" />
              {/* <CalendarMonthIcon className="icon" fontSize="medium" sx={{color: !darkMode ? "#000":(darkMode && location.pathname=="/events")?"#000":"#fff"}}/> */}
              <span className={isMessagesPage ? "messagePage" : ""}>Competitions</span>
            </Link>
          </div>

          {/* <div className={`item ${location.pathname.includes("/courses") ? "active" : ""}`}>
            <Link to="/courses"  className={`item-link ${location.pathname.includes("/courses") ? "active" : ""}`}>
              <DesktopMacIcon className="icon" fontSize="medium" sx={{color: !darkMode ? "#000":(darkMode && location.pathname=="/courses")?"#000":"#fff"}}/>
              <span className={isMessagesPage ? "messagePage" : ""}>Courses</span>
            </Link>
          </div> */}

        

        </div>
        <hr />
        {/* <div className="menu">
          <span>Your shortcuts</span>
          <div className="item">
            <img src={Events} alt="" />
            <span>Events</span>
          </div>
          <div className="item">
            <img src={Gaming} alt="" />
            <span>Gaming</span>
          </div>
          <div className="item">
            <img src={Gallery} alt="" />
            <span>Gallery</span>
          </div>
          <div className="item">
            <img src={Videos} alt="" />
            <span>Videos</span>
          </div>
          <div className="item">
            <img src={Messages} alt="" />
            <span>Messages</span>
          </div>
        </div> */}
        {/* <hr /> */}
        {/* <div className="menu">
          <span>Others</span>
          <div className="item">
            <img src={Fund} alt="" />
            <span>Fundraiser</span>
          </div>
          <div className="item">
            <img src={Tutorials} alt="" />
            <span>Tutorials</span>
          </div>
          <div className="item">
            <img src={Courses} alt="" />
            <span>Courses</span>
          </div>
        </div> */}
      </div>
      <div className="bottom">
      <Link to={`/profile/${currentUser.UUID}`}>
            <div className="user" style={{display:'flex',alignItems:'center'}}>
              <img
                src={currentUser.profilePic}
                alt=""
                width={"40px"}
                height={"40px"}
                style={{borderRadius:"50%"}}
              />
            </div>
          </Link>
          <Link to="/" className={`item-link ${location.pathname.includes("/") ? "active" : ""}`} style={{display:'flex',alignItems:'center'}}>
        {/* <HomeOutlinedIcon className="icon" fontSize="medium" sx={{ color: !darkMode ? "#000":(location.pathname=="/")?"#000":"#fff",cursor:"pointer"}}/> */}
        <img src={home} width="25px"/>
            </Link>
          <Link to="/connect" className={`item-link ${location.pathname.includes("/friends") ? "active" : ""}`} style={{display:'flex',alignItems:'center'}}>
              {/* <GroupIcon className="icon" fontSize="medium" sx={{color: !darkMode ? "#000":(darkMode && location.pathname=="/friends")?"#000":"#fff"}}/> */}
              <img src={groups} alt="" width={"25px"} />
            </Link>
              <img src={logoutImg} alt="" width={"25px"} onClick={() => setIsModalVisible(true)}/>
      </div>
      <Modal
        title="Logout Confirmation"
        visible={isModalVisible}
        onOk={handleLogout}
        onCancel={handleCancel}
        footer={[
          <button
            style={{
              backgroundColor: "#e4002b",
              color: "white",
              border: "none",
              borderRadius: "5px",
              width: "4rem",
              height: "2rem",
              fontWeight: "600",
              fontSize: "0.9rem",
            }}
            key="logout"
            type="primary"
            onClick={handleLogout}
          >
            Logout
          </button>,
        ]}
      >
        <p>Are you sure you want to logout?</p>
      </Modal>
    </div>
  );
};

export default LeftBar;
