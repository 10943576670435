// import React, { useContext } from "react";
// import "./userPageCard.scss";
// import { Link } from "react-router-dom";
// import image from "../../assets/admin_img.jpg";
// import moment from "moment";
// import DeleteIcon from "@mui/icons-material/Delete";
// import { DarkModeContext } from "../../context/darkModeContext";
// import { makeRequest } from "../../axios";
// import { message, Modal } from "antd";

// function UserPageCard({ page }) {
//   console.log(page);
//   let desc = page.pageDescription.slice(0, 35);
//   console.log(desc);
//   const {darkMode} = useContext(DarkModeContext)

//   const deletePage=async(page)=>{
//     try{
//       const response = await makeRequest.delete(`/ssakti/users/pages/deletePage/${page.adminId}/${page.pagesId}`)
//       if(response){
//         message.success("Page deleted successfully");
//       }
//     }
//     catch(err){
//       console.error(err);
//       message.error("Failed to delete group");
//     }
//   }

//   return (
//     <div className="card">
//       <Link to={`/userpage/${page.pagesId}`}>
//         <img src={page.pageCoverProfileImagePath} alt="user" />
//         <div className="head">
//           <h3>{page.pageName}</h3>
//           <DeleteIcon
//           onClick={()=>deletePage(page)}
//             className="size-4"
//             sx={{ color: !darkMode ? "#000" : "#fff" }}
//           />
//         </div>
//         <p>{page.category}</p>
//         <h6>{desc + "..."}</h6>
//         <h6>
//           Created by {page.adminUserFirstName} {page.adminUserLastName}
//         </h6>
//         <h6>Created on {moment(page.pageCreatedAt).format("MMMM Do YYYY")}</h6>
//       </Link>
//     </div>
//   );
// }

// export default UserPageCard;


import React, { useContext, useState } from "react";
import "./userPageCard.scss";
import { Link } from "react-router-dom";
import image from "../../assets/admin_img.jpg";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import { DarkModeContext } from "../../context/darkModeContext";
import { makeRequest } from "../../axios";
import { message, Modal, notification } from "antd";
import { AuthContext } from "../../context/authContext";
import { useQueryClient } from "@tanstack/react-query";

function UserPageCard({ page,getUserPages,fetchPages }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageToDelete, setPageToDelete] = useState(null); // Track the page to delete
  const queryClient = useQueryClient();

  const { darkMode } = useContext(DarkModeContext);
  const {currentUser} = useContext(AuthContext)

  const showModal = (page) => {
    setPageToDelete(page); // Set the page to delete
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setPageToDelete(null);
  };

  const deletePage = async () => {
    if (pageToDelete) {
      try {
        const response = await makeRequest.delete(`/users/pages/deletePage/${pageToDelete.adminId}/${pageToDelete.pagesId}`);
        console.log(response)
        if (response.status === 200) {
          message.success("Feed deleted successfully");
          // You might want to add some logic here to refresh or update the page list
          getUserPages();
        }
      } catch (err) {
        console.error(err);
        message.error("Failed to delete Feed");
      } finally {
        setIsModalVisible(false);
        setPageToDelete(null);
      }
    }
  };

  const followPage=async(e)=>{
    // e.stopPropagation();
    try {
      const res = await makeRequest.post(`/users/pages/followPage/${currentUser.id}/${page.pagesId}`)
      if(res.status === 201){
        notification.success({
          message: "Followed successfully",
          description: res.data.message,
        });
        queryClient.invalidateQueries(['pagesList'])
      }
      // else{
      //   notification.error({
      //     message: "Follow Failed",
      //     description: "You already followed the page.",
      //   });
      // }
    } catch (error) {
      notification.error({
        message: "Follow Failed",
        description: "There was an error following the feed.",
      });
    }
  }

  const unFollowPage=async(e)=>{
    // e.stopPropagation();
    try {
      const res = await makeRequest.delete(`/users/pages/exitPage/${page.adminId}/${page.pagesId}/${currentUser.id}`)
      console.log(res)
      if(res.status === 200){
        notification.success({
          message: "Unfollowed successfully",
          description: "You have successfully unfollowed the feed.",
        });
        queryClient.invalidateQueries(['pagesList'])
      }
      // else{
      //   notification.error({
      //     message: "Unfollow Failed",
      //     description: "You were not following the page.",
      //   });
      // }
    } catch (error) {
      console.error("Error unfollowing page:", error);
      notification.error({
        message: "Unfollow Failed",
        description: "There was an error unfollowing the feed.",
      });
    }
  }

  return (
    <div className="card">
      <div>
        <Link to={`/userFeeds/${page.pagesId}`}>
        <img src={page.pageCoverProfileImagePath || image} alt="user" />
        </Link>
        <div className="head">
          <h3>
            <Link to={`/userFeeds/${page.pagesId}`}>
            {page.pageName}
            </Link>
            </h3>
            {
              currentUser.id === page.adminId ?
          <DeleteIcon
            onClick={(e) => {showModal(page)}} // Show modal on click
            className="size-4"
            sx={{ color: !darkMode ? "#000" : "#fff" }}
          />
          :
          page.isPageFollowed === true ? (
            <button className="button-upg" onClick={unFollowPage}>UnFollow</button>
          ) : (
            <button className="button-upg" onClick={followPage}>Follow</button>
          )
            }
        </div>
        <p>{page.category}</p>
        <h6>{page.pageDescription.slice(0, 35) + "..."}</h6>
        <h6>
          Created by {page.adminUserFirstName} {page.adminUserLastName}
        </h6>
        <h6>Created on {moment(page.pageCreatedAt).format("MMMM Do YYYY")}</h6>
      </div>

      <Modal
        title="Confirm Delete"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <button
            key="cancel"
            onClick={handleCancel}
            style={{
              backgroundColor: "#ccc",
              color: "#000",
              border: "none",
              borderRadius: "5px",
              width: "4rem",
              height: "2rem",
              fontWeight: "600",
              fontSize: "0.9rem",
            }}
          >
            Cancel
          </button>,
          <button
            key="delete"
            onClick={deletePage}
            style={{
              backgroundColor: "#e4002b",
              color: "white",
              border: "none",
              borderRadius: "5px",
              width: "4rem",
              height: "2rem",
              fontWeight: "600",
              fontSize: "0.9rem",
            }}
          >
            Delete
          </button>,
        ]}
      >
        <p>Are you sure you want to delete this page?</p>
      </Modal>
    </div>
  );
}

export default UserPageCard;
