import React, { useContext, useEffect, useState } from 'react'
import "./yourComp.scss"
import { Link } from 'react-router-dom'
import Img from "../../assets/demo2.jpg"
import { AuthContext } from '../../context/authContext';
import { makeRequest } from '../../axios';
import Watch from "../../assets/clock.png"
import Calendar from "../../assets/schedule.png"
import Location from "../../assets/map.png"
import { message, Modal, Pagination } from 'antd';
import DeleteIcon from "@mui/icons-material/Delete";

function YourCompetitions() {

    const { currentUser } = useContext(AuthContext);
  const [hasNextPage, setHasNextPage] = useState();
  const [userComp, setUserComp] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5); // Default page size
  const [hasComp,setHasComp] = useState(false)
  const [districts,setDistricts] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedComp,setSelectedComp] = useState()

  const getUserComp = async (page = 1, size = 5) => {
    try {
      const response = await makeRequest.get(`/users/competition/getHostCompetition`, {
        params: { page: page - 1, size },
      });
      setUserComp(response.data.details);
      setTotalElements(response.data.totalElements || 0);
      setHasNextPage(response.data.hasNextPage)
      setHasComp(response.data.details.length > 0);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(()=>{
    getUserComp();
  },[])

  const onPageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const handleDelCmp = async () => {
    if (selectedComp) {
      try {
        const response = await makeRequest.delete(`/users/competition/delete/${selectedComp.compId}`);  
        if (response) {
          message.success("Competitiion deleted successfully");
          getUserComp()
        } else {
          message.error("Failed to delete Organisation, try later");
        }
        console.log(selectedComp.compId)
      } catch (err) {
        console.error(err);
        message.error("Failed to delete Organisation");
      } finally {
        setIsModalVisible(false);
        setSelectedComp(null);
      }
    }
  };


  return (
//     
        <div className='eventsPage'>
        <div className='head'>
            <h1>Competitions for you!</h1>
            <div style={{display:"flex",gap:"5px",alignItems:"center",justifyContent:"center"}}>
            <button> <Link to={'/createComp'}>Create a Competitiion</Link></button>
            <button> <Link to={'/yourCompetitions'}>Your Competitiions</Link></button>
            </div>
        </div>
        <hr/>
        <div className='events'>
            {
                userComp && userComp.map((cmp)=>{
                    return(
                        <div className='comp-single'>
                <div><Link to={`/competitions/${cmp.compUUID}`}><img src={cmp.compCoverProfileImagePath} width={"300px"} height={"200px"}/></Link></div>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                <div className='details' style={{width:"80%"}}>
                    <h3>{cmp.compName}</h3>
                    <div style={{display:"flex",gap:'10px',margin:"3px"}}>
                    <img src={Calendar} width={"20px"} height={"20px"}/>
                    <p style={{fontSize:"12px"}}>{cmp.startDate} TO {cmp.endDate}</p>
                    </div>
                    <div style={{display:"flex",gap:'10px',margin:"3px"}}>
                    <img src={Watch} width={"20px"} height={"20px"}/>
                    <p style={{fontSize:"12px"}}>{cmp.startTime} TO {cmp.endTime}</p>
                    </div>
                    <div style={{display:"flex",gap:'10px',margin:"3px"}}>
                    <img src={Location} width={"20px"} height={"20px"}/>
                    <p style={{fontSize:"12px"}}>{cmp.compAddress}</p>
                    </div>
                </div>
                <div className="deleteGrp" style={{display:'flex',alignItems:"center",justifyContent:'center',width:"20%"}}>
                    <button onClick={() => { setSelectedComp(cmp); setIsModalVisible(true); }}>
                      <DeleteIcon
                        className="size-4"
                        sx={{ color:"#000" }}
                      />
                    </button>
                  </div>
                  
                </div>    
            </div>
                    )
                })
            }
        <Modal
      title="Delete Organisation"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <button
            style={{
              backgroundColor: "#e4002b",
              color: "white",
              border: "none",
              borderRadius: "5px",
              width: "4rem",
              height: "2rem",
              fontWeight: "600",
              fontSize: "0.9rem",
            }}
            key="delete"
            type="primary"
            onClick={handleDelCmp}
          >
            Delete
          </button>,
        ]}
      >
        <p>Are you sure you want to delete this Competitiion?</p>
      </Modal>
        </div>
            {
              !userComp.length > 0 && <div style={{height:"100%",display:"flex",alignItems:"center",justifyContent:'center'}}>
                <h1>No competitions found at your selected district.</h1>
                </div>
            }
        {
            hasNextPage &&
        <Pagination
        style={{marginTop:"10px"}}
        current={currentPage}
        pageSize={pageSize}
        total={totalElements}
        onChange={onPageChange}
        showSizeChanger
        pageSizeOptions={[2,4,6,8,10]}
        />
    }
    </div>
  )
}

export default YourCompetitions
