import React, { useContext, useState } from "react";
import { message, notification } from "antd"; // Assuming you are using Ant Design for notifications
import "./CreateCompetition.scss"; // Custom styles
import Image from "../../assets/picture.png";
import imageCompression from "browser-image-compression";
import { makeRequest } from "../../axios";
import { AuthContext } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const CreateCompetition = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [file, setFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { currentUser } = useContext(AuthContext);
  // const [hasOrgAdded, setHasOrgAdded] = useState(currentUser.organisationName);
  const [hasOrgAdded, setHasOrgAdded] = useState(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    return user.organisationName
  })

  console.log(hasOrgAdded)
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (
      !name ||
      !description ||
      !startDate ||
      !endDate ||
      !address ||
      !startTime ||
      !endTime
    ) {
      notification.error({
        message: "Validation Error",
        description: "Please fill out all fields.",
      });
      setIsSubmitting(false);
      return;
    }

    // Here, you would typically send the data to your server
    const competitionData = {
      name,
      address,
      description,
      startDate,
      endDate,
      endTime,
      startTime,
      file,
    };
    let fileToUpload = null;
    try {
      if (file) {
        console.log("file is not null");
        if (file.type.includes("image")) {
          const imageOptions = {
            maxSizeMB: 0.1, // 150KB
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          };
          const compressedBlob = await imageCompression(file, imageOptions);

          // Convert the compressed Blob back to a File
          fileToUpload = new File([compressedBlob], file.name, {
            type: compressedBlob.type,
            lastModified: file.lastModified,
          });
        } else {
          message.error("Only images are allowed!");
          return;
        }
      }

      // Replace with your API call
      console.log('File is null');
      let formdata = new FormData();
      formdata.append("name", name);
      formdata.append("description", description);
      formdata.append("address", address);
      formdata.append("startDate", startDate);
      formdata.append("endDate", endDate);
      formdata.append("endTime", endTime);
      formdata.append("startTime", startTime);
      // formdata.append("imageUrl", fileToUpload);

      if (fileToUpload) {
        formdata.append("imageUrl", fileToUpload); // Append the file directly
      } 
      

      console.log("this is file", fileToUpload);
      console.log("this is file  from formdata", formdata.get("imageUrl"));
      console.log("this is 1st file", file);
      const response = await makeRequest.post(
        `/users/competition/create/${currentUser.districtId}`,
        formdata,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      // console.log(response)
      if (response.data.status) {
        notification.success({
          message: "Competition Created",
          description: "Your competition has been successfully created.",
        });
        navigate("/competitions");
        // Reset form
        setName("");
        setAddress("");
        setDescription("");
        setStartDate("");
        setEndDate("");
        setStartTime("");
        setEndTime("");
        setFile(null);
      }
      // for (let pair of formdata.entries()) {
      //   if (pair[0] === 'imageUrl') {
      //     console.log(pair[0] + ": " + pair[1].name); // Log the file name
      //     console.log("File type:", pair[1].type);    // Log the file type
      //     console.log("File size:", pair[1].size);    // Log the file size
      //   } else {
      //     console.log(pair[0] + ": " + pair[1]); // Log other data (non-file)
      //   }
      // }
    } catch (error) {
      console.error("Error creating competition:", error);
      notification.error({
        message: "Submission Error",
        description: "There was an error creating the competition.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {hasOrgAdded !== null ? (
        <div className="createComp">
          <h1>Create Competition</h1>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Competition Name</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="address">Address</label>
              <textarea
                id="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
              />
            </div>
            <div className="date-grp">
              <div className="form-group">
                <label htmlFor="startDate">Start Date</label>
                <input
                  type="date"
                  id="startDate"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="endDate">End Date</label>
                <input
                  type="date"
                  id="endDate"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="time-grp">
              <div className="form-group">
                <label htmlFor="startTime">Start Time</label>
                <input
                  type="time"
                  id="startTime"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="endTime">End Time</label>
                <input
                  type="time"
                  id="endTIme"
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="file-grp">
              <div>
                <label
                  htmlFor="file-comp"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <img src={Image} width={"50px"} />
                  Add image
                </label>
                <input
                  id="file-comp"
                  accept="image/*"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </div>
              <div>
                {file && (
                  <img
                    src={URL.createObjectURL(file)}
                    style={{
                      borderRadius: "20px",
                      width: "100px",
                      height: "100px",
                    }}
                  />
                )}
              </div>
            </div>
            <button className="button" type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Creating..." : "Create Competition"}
            </button>
          </form>
        </div>
      ) : (
        <div className="orgAdd">
          <h1>You will need to add organisation to create competition.</h1>
          <h3>Try updating your profile.</h3>
          <button onClick={() => navigate(`/profile/${currentUser.UUID}`)}>
            Add Organisation
          </button>
        </div>
      )}
    </>
  );
};

export default CreateCompetition;
