import React, { useEffect, useContext } from 'react';
import Post from "../post/Post";
import "./posts.scss";
import { useInfiniteQuery } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { AuthContext } from "../../context/authContext";
import LoadingPost from "../loading/LoadingPost";
import CircularProgress from '@mui/material/CircularProgress';

const Posts = ({ userId , isTimeline}) => {
  const { currentUser } = useContext(AuthContext);
  console.log(currentUser)

  // Define fetch function for useInfiniteQuery
  const fetchPosts = async ({ pageParam = 0 }) => {
    console.log(userId)
    const endpoint = isTimeline
      ? `/users/user/userTimeline/${currentUser.UUID}/${userId == currentUser.UUID ? "sameUser" :userId}`
      : `/users/home/home/v1/${currentUser.id}`;
  
    try {
      const response = await makeRequest.get(endpoint, {
        params: { cursor: pageParam, size: 6 }, 
      });
      console.log("posts are",response)
      if (response.status === 204) {
        // Handle 204 No Content response
        console.log('No content received from server');
        return {
          data: [],
          homePostData: [],
          postsData: [],
          hasNextPage: false,
          nextPageNo: undefined,
        };
      }
  
      console.log('Fetched data:', response.data);
  
      return response.data;
    } catch (error) {
      console.error('Fetch error:', error); 
      throw error;
    }
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isError,
  } = useInfiniteQuery(
    ['posts', userId],
    fetchPosts,
    {
      getNextPageParam: (lastPage) => {
        // console.log('Last page:', lastPage); 
        return lastPage.hasNextPage ? lastPage.nextCursor : undefined;
      },
      refetchOnWindowFocus:false,
    }
  );

  // Detect scroll event
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop
        < document.documentElement.offsetHeight - 100 || // Trigger a bit before the bottom
        isFetching
      ) return;

      if (hasNextPage) {
        fetchNextPage();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [fetchNextPage, hasNextPage, isFetching]);

  return (
    <div className="posts">
      {isError ? (
        <div>No Posts!</div>
      ) : isLoading  ? (
        // <LoadingPost />
        <div className='fetching'>
          <CircularProgress />
        </div>
      ) : data.pages.length === 0 || data?.length ===0  ? (
        <div>No Posts!</div>
      ) : (
        data.pages.flatMap(page => page?.homePostData || page?.postsData).map(post => (
          <Post post={post} key={post.postId} />
        ))
      )}
      {/* Optionally, you can show a loading indicator for fetching more posts */}
      
      {isFetching && !isLoading && <div className='fetching'><CircularProgress/></div>}
    </div>
  );
};

export default Posts;
