import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { AuthContext } from '../../context/authContext';
import { makeRequest } from '../../axios';
import "./singleEvent.scss"
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FmdGoodIcon from '@mui/icons-material/FmdGood';

function SingleEvent() {

    const params = useParams();
    const eventId = params.id
    const {currentUser} = useContext(AuthContext)
    const [eventAbout,setEventAbout] = useState(null)
    const [eventHost,setEventHost] = useState(null)

    const getEventDetails = async()=>{
        try {
            const response = await makeRequest.get(`/users/event/getDetails/${currentUser.id}/${eventId}`)
            console.log(response)
            setEventAbout(response.data.about[0])
            setEventHost(response.data.discussion[0])
        } catch (error) {
            console.error(error)
            setEventAbout({})
            setEventHost({})
        }
    }

    useEffect(()=>{
        getEventDetails()
    },[])

  return (
    <div className='singleEventPage'>
      <img className='eveCover' src={eventAbout?.postImageUrl}/>
      <div className='date'>
        <div className='headDate'></div>
        <div className='btDate'>{eventAbout?.startDate.split("-").slice(2,5)}</div>
      </div>
      <div className='eventDetails'>
        <div style={{display:"flex",width:"100%",justifyContent:'space-between',alignItems:"center"}}>
        <div>
        <h2>{eventAbout?.eventName} </h2>
        <h5>{eventAbout?.eventDescription} </h5>
        </div>
        <div className='catName'>
            <h6>{eventAbout?.categoryName}</h6>
        </div>
        </div>
        <div className='detDesc'>
            <AccessTimeIcon /> {eventAbout?.startTime} - {eventAbout?.endTime}
        </div>
        <div className='detDesc'>
            <CalendarMonthIcon /> {eventAbout?.startDate}   -   {eventAbout?.endDate}
        </div>
        <div className='detDesc'>
            <FmdGoodIcon /> {eventAbout?.eventAddress} {eventAbout?.districtName}
        </div>
        <hr style={{marginTop:"50px",marginBottom:"50px"}}/>
        <h2>Hosted by :</h2>
        <div style={{display:'flex',gap:"20px",alignItems:"center",margin:"10px"}}>
            <img src={eventHost?.userProfileImageUrl} width={"50px"} height={"50px"} style={{borderRadius:"50%",objectFit:"cover"}}/>
            <Link to={`/profile/${eventHost?.userId}`} className='userLink'>{eventHost?.userName}</Link>
        </div>
        <h5>This event was hosted {eventHost?.postUploadedAt}</h5>
      </div>
    </div>
  )
}

export default SingleEvent
