import React, { useContext, useEffect, useState } from 'react'
import "./competitions.scss"
import { Link } from 'react-router-dom'
import Img from "../../assets/demo2.jpg"
import { AuthContext } from '../../context/authContext';
import { makeRequest } from '../../axios';
import Watch from "../../assets/clock.png"
import Calendar from "../../assets/schedule.png"
import Location from "../../assets/map.png"
import { Pagination } from 'antd';

function Competitions() {

    const { currentUser } = useContext(AuthContext);
  const [hasNextPage, setHasNextPage] = useState();
  const [userComp, setUserComp] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5); // Default page size
  const [hasComp,setHasComp] = useState(false)
  const [districts,setDistricts] = useState([])
  const [dist,setDist] = useState();

  useEffect(() => {
    if (currentUser && currentUser.districtId) {
        setDist(currentUser.districtId);
    }
}, [currentUser]);


  const getUserComp = async (page = 1, size = 5,dis) => {
    try {
      const response = await makeRequest.get(`/users/competition/getAllCompetition/${dis}`, {
        params: { page: page - 1, size },
      });
      console.log(response);
      setUserComp(response.data.details);
      setTotalElements(response.data.totalElements || 0);
      setHasNextPage(response.data.hasNextPage)
      setHasComp(response.data.details.length > 0);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if(dist){
      getUserComp(currentPage, pageSize,dist);
    }
  }, [currentPage, pageSize,dist]);

  const onPageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  useEffect(() => {
    if (dist) {
        getUserComp(currentPage, pageSize, dist);
    }
}, [dist, currentPage, pageSize]);


  const fetchDistricts = async () => {
    try {
      const response = await makeRequest.get("/users/district/getAllDistricts");
      setDistricts(response.data.getAllDistrictData);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(()=>{
      fetchDistricts();
  },[])



  return (
//     
        <div className='eventsPage'>
        <div className='head'>
            <h1>Competitions for you!</h1>
            <select onChange={(e)=>setDist(e.target.value)}>
            <option>Select district to see events</option>
            {
                districts && districts.map((item, index) => (
                    <option key={index} value={item.districtId}>{item.districtName}</option>
                ))
            }
        </select>
            <div style={{display:"flex",gap:"5px",alignItems:"center",justifyContent:"center"}}>
            <button> <Link to={'/createComp'}>Create a Competitiion</Link></button>
            <button> <Link to={'/yourCompetitions'}>Your Competitiions</Link></button>
            </div>
        </div>
        <hr/>
        <div className='events'>
            {
                userComp && userComp.map((cmp)=>{
                    return(
                        <div className='comp-single'>
                <div><Link to={`/competitions/${cmp.compUUID}`}><img src={cmp.compCoverProfileImagePath} width={"300px"} height={"200px"}/></Link></div>
                <div className='details'>
                    <h3>{cmp.compName}</h3>
                    <div style={{display:"flex",gap:'10px',margin:"3px"}}>
                    <img src={Calendar} width={"20px"} height={"20px"}/>
                    <p style={{fontSize:"12px"}}>{cmp.startDate} TO {cmp.endDate}</p>
                    </div>
                    <div style={{display:"flex",gap:'10px',margin:"3px"}}>
                    <img src={Watch} width={"20px"} height={"20px"}/>
                    <p style={{fontSize:"12px"}}>{cmp.startTime} TO {cmp.endTime}</p>
                    </div>
                    <div style={{display:"flex",gap:'10px',margin:"3px"}}>
                    <img src={Location} width={"20px"} height={"20px"}/>
                    <p style={{fontSize:"12px"}}>{cmp.compAddress}</p>
                    </div>
                </div>
            </div>
                    )
                })
            }
    
        </div>
            {
              !userComp.length > 0 && <div style={{height:"100%",display:"flex",alignItems:"center",justifyContent:'center'}}>
                <h1>No competitions found at your selected district.</h1>
                </div>
            }
      <Pagination
      style={{marginTop:"10px"}}
      current={currentPage}
      pageSize={pageSize}
      total={totalElements}
      onChange={onPageChange}
      showSizeChanger
      pageSizeOptions={[2,4,6,8,10]}
      />
    </div>
  )
}

export default Competitions
