import React, { useState, useContext, useEffect } from "react";
import Post from "../../components/post/Post";
import AboutComp from "./AboutComp";
import FriendsListing from "../friendsListing/FriendsListng"
import './tabs.scss';
import { AuthContext } from "../../context/authContext";
import { makeRequest } from "../../axios";
import { notification } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import AWS from "aws-sdk";
import heic2any from "heic2any";
import SendIcon from "@mui/icons-material/Send";
import Image from "../../assets/picture.png";
import { Navigate, useNavigate } from "react-router-dom";
import imageCompression from "browser-image-compression";
import { useInfiniteQuery } from "@tanstack/react-query";
import { CircularProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

const awsRegion = process.env.REACT_APP_AWS_REGION;
const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;

const ProfileTabsComp = ({ groupMembers, userId, selectedTab, data, post,params ,getCompDetails,compAbout}) => {

  console.log("this is data form tabs",data)
  return (
    <div className="profileTabs">

      <div className="tabContent">
        {selectedTab === "Posts" && <CompPost dataComp={data} params={params} getCompDetails={getCompDetails} />}
        {selectedTab === "AboutComp" && <AboutComp data={data} />}
        {selectedTab === "Participants" && <MembersComp dataComp={data}  params={params} />}
        {/* {selectedTab === "Friends" && <FriendsListing userId={userId} />} */}
      </div>
    </div>
  );
};

export default ProfileTabsComp;


export const CompPost = ({ post, dataComp,params,getCompDetails}) => {

  console.log('this is post :',dataComp)

  const { currentUser } = useContext(AuthContext)

  const [file, setFile] = useState(null);
  const [desc, setDesc] = useState("");
  const [inputNameError, setInputNameError] = useState("");

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (newPost) => {
      return makeRequest.post(
        `/users/competition/addPost/${params.compId}`,
        newPost,
        {
          headers: {
            "Content-Type": "multipart/form-data",  // Manually setting the Content-Type header
          },
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["cposts"]);
        getCompDetails();
        notification.success({
          message: "Post uploaded successfully",
          description: "Your post has been shared with everyone.",
        });
      },
    }
  );

  const handleInput = (e) => {
    setDesc(e.target.value);
    if (e.target.value.length > 0) {
      setInputNameError("");
    }
  };



  // const handleClick = async () => {
  //   if (!desc.trim() && !file) {
  //     notification.error({
  //       message: "No Image or No Description",
  //       description: "You have to add an image or a description.",
  //     });
  //     return;
  //   }
  
  
  //   let fileToUpload = file;
  
  //   if (file) {
  //     try {
  //       // Handle HEIC to JPEG conversion
  //       if (file.type === "image/heic") {
  //         const convertedBlob = await heic2any({
  //           blob: file,
  //           toType: "image/jpeg",
  //         });
  //         fileToUpload = new File(
  //           [convertedBlob],
  //           file.name.replace(/\.[^/.]+$/, ".jpg"),
  //           { type: "image/jpeg" }
  //         );
  //       }
  
  //       // Compress image if it's an image file
  //       if (fileToUpload.type.startsWith("image")) {
  //         const imageOptions = {
  //           maxSizeMB: 0.1, // 150KB
  //           maxWidthOrHeight: 1920,
  //           useWebWorker: true,
  //         };
  //         const compressedBlob = await imageCompression(fileToUpload, imageOptions);
  //         fileToUpload = new File([compressedBlob], file.name, {
  //           type: compressedBlob.type,
  //           lastModified: file.lastModified,
  //         });
  //       } 
        
  //       // Check video size if it's a video file
  //       else if (fileToUpload.type.includes("video")) {
  //         const maxVideoSizeMB = 10;
  //         const videoSizeMB = file.size / 1024 / 1024;
  //         if (videoSizeMB > maxVideoSizeMB) {
  //           notification.error({
  //             message: 'Video Too Large',
  //             description: `The video size is ${videoSizeMB.toFixed(2)} MB, which exceeds the 10MB limit.`,
  //           });
  //           return;
  //         }
  //       }
  
  //       // Upload the file
  //       const formData = new FormData();
  //       formData.append("postName", desc);
  //       formData.append("postImage", fileToUpload);
  //       formData.append("postType", fileToUpload.type);
  
  //       console.log("FormData:", formData.get("postImage"));
  
  //       mutation.mutate(formData);
  //     } catch (error) {
  //       console.log("Error during processing or upload:", error);
  //       notification.error({
  //         message: "Upload Failed",
  //         description: `There was an error: ${error.message}`,
  //       });
  //     } finally {
  //       setFile(null);
  //       setDesc("");
  //     }
  //   }
  // };
  
  const handleClick = async () => {
    if (!desc && !file) {
      notification.error({
        message: "No Image or No Description",
        description: "You have to add an image or a description.",
      });
      return;
    }


    let fileToUpload = file;

    if (file) {
      try {
        if (file.type.startsWith("image")) {
          // Compress image to be less than 150KB
          const imageOptions = {
            maxSizeMB: 0.1, // 150KB
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          };
          const compressedBlob = await imageCompression(file, imageOptions);

          // Convert the compressed Blob back to a File
          fileToUpload = new File([compressedBlob], file.name, {
            type: compressedBlob.type,
            lastModified: file.lastModified,
          });
        } else if (file.type.includes("video")) {
          // Check if the video size exceeds 10MB
          const maxVideoSizeMB = 10;
          const videoSizeMB = file.size / 1024 / 1024;
          if (videoSizeMB > maxVideoSizeMB) {
            notification.error({
              message: 'Video Too Large',
              description: `The video size is ${videoSizeMB.toFixed(2)} MB, which exceeds the 10MB limit.`,
            });
            return;
          }

          fileToUpload = file;
        }

        // Upload the file
        const formData = new FormData();
        formData.append("postName", desc);
        formData.append("postImage", fileToUpload);
        formData.append("postType", file.type);
        console.log("this is formdata",formData.get("postName"))
       
        mutation.mutate(formData);
      } catch (error) {
        console.log("Error during compression or upload:", error);
      } finally {
        setFile(null);
        setDesc("");
      }
    }
  };

  //   const endpoint = `/users/competition/getCompetitionPosts/${params.compId}`;
  //   try {
  //     const response = await makeRequest.get(endpoint, {
  //       params: { cursor: pageParam, size: 6 }, // Pagination params
  //     });
  
  //     console.log('Fetched data:', response.data);
  
  //     if (response.status === 204) {
  //       // Handle no content received from server
  //       console.log('No content received');
  //       return {
  //         data: [],
  //         hasNextPage: false,
  //         nextPageNo: undefined,
  //       };
  //     }
  
  //     const { details, hasNextPage, nextPageNo } = response.data; // Extract posts and pagination info
  
  //     // Check if the 'details' array contains any posts
  //     if (!details || details.length === 0) {
  //       console.log('No posts found');
  //       return {
  //         data: [],
  //         hasNextPage: false,
  //         nextPageNo: undefined,
  //       };
  //     }
  
  //     return {
  //       data: details, // The posts are inside 'details'
  //       hasNextPage,
  //       nextPageNo,
  //     };
  //   } catch (error) {
  //     console.error('Error fetching posts:', error);
  //     throw error; // Ensure error handling is in place
  //   }
  // };
  

  const fetchPosts = async ({ pageParam = 0 }) => {
    const endpoint = `/users/competition/getCompetitionPosts/${params.compId}`;
    try {
      const response = await makeRequest.get(endpoint, {
        params: { cursor: pageParam, size: 6 }, // Paginate results
      });
  
      // If the response status is 204, handle "no content" scenario
      if (response.status === 204) {
        console.log('No content received from server');
        return {
          data: [],
          hasNextPage: false,
          nextPageNo: undefined,
        };
      }
  
      // Otherwise, process the posts and pagination metadata
      return {
        data: response.data.details,  // Posts list
        hasNextPage: response.data.hasNextPage,  // More posts available?
        nextPageNo: response.data.nextPageNo,  // The next page number
      };
    } catch (error) {
      console.error('Fetch error:', error);
      throw error;
    }
  };
  

 

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isError,
  } = useInfiniteQuery(
    ['cposts', currentUser.id], // Query key
    fetchPosts, // Fetch function
    {
      getNextPageParam: (lastPage) => {
        // Return nextPageNo if there are more pages, otherwise return undefined
        return lastPage.hasNextPage ? lastPage.nextPageNo : undefined;
      },
      refetchOnWindowFocus: false, // Optional: prevent refetching on window focus
    }
  );

  // Detect scroll event
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop
        < document.documentElement.offsetHeight - 100 || // Trigger a bit before the bottom
        isFetching
      ) return;

      if (hasNextPage) {
        fetchNextPage();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [fetchNextPage, hasNextPage, isFetching]);


  return (
    <>
    {
       currentUser.id === dataComp.adminId &&
     <div className="shareGrp" style={{width:"80%",marginRight:"auto",marginLeft:'auto'}}>
              <div className="container">
                <div className="top">
                  <div className="left">
                    <img src={currentUser.profilePic} alt="" />
                    <textarea
                      rows={"3"}
                      type="text"
                      placeholder={`What's on your mind ${currentUser.name}?`}
                      onChange={handleInput}
                      value={desc}
                    />
                  </div>
                  <div className="right">
                    {file && file.type.startsWith("image/") && (
                      <img
                        className="file"
                        alt=""
                        src={URL.createObjectURL(file)}
                      />
                    )}
                    {file && file.type.startsWith("video/") && (
                      <video className="file" controls>
                        <source
                          src={URL.createObjectURL(file)}
                          type={file.type}
                        />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    {!file && <p>No file selected</p>}{" "}
                    {/* Message when no file is selected */}
                  </div>
                </div>
                {inputNameError && (
                  <span className="inputError">{inputNameError}</span>
                )}
                <hr />
                <div className="bottom">
                  <div className="left">
                    <input
                      type="file"
                      id="file"
                      accept="image/*,video/*"
                      style={{ display: "none" }}
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                    <label htmlFor="file">
                      <div className="item">
                        <img src={Image} alt="" width={"40px"} height={"40px"}/>
                        <span>Add Notice</span>
                      </div>
                    </label>
                  </div>
                  <div className="rightGrp">
                    <button onClick={handleClick}>
                      Share
                    </button>
                  </div>
                </div>
              </div>
            </div>
    }
    {isError && <h1 style={{color:"black"}}>No Posts!</h1>}
    {isLoading && <CircularProgress />}
    
    {/* Display posts */}
    {!isLoading && !isError && (
      <>
        {data?.pages.flatMap(page => page.data).map(post => (
          <Post post={post} key={post.postId} />
        ))}
        {/* <h1>{data.length}</h1> */}

        {/* No posts available */}
        {data?.pages.length === 0 || data?.pages.flatMap(page => page.data).length === 0 ? (
          <h1 style={{color:"black"}}>No Posts!</h1>
        ) : null}
      </>
    )}
    </>
  )
}

export const MembersComp = ({ dataComp, params}) => {
const queryClient = useQueryClient();

  const { currentUser } = useContext(AuthContext)

  console.log(dataComp)

  // console.log(currentUser)
  const navigate = useNavigate()
  // const removeUserFromGroupByAdmin = async (id) => {
  //   try {
  //     const response = await makeRequest.delete(`/users/group/exitGroup/${data.groupId}/${data.adminId}/${id}`)
  //     console.log(response)
  //     if (response) {
  //       notification.success({
  //         message: 'User removed successfully',
  //       });
  //       // navigate(`/groups`)
  //      queryClient.invalidateQueries(["groupDetails"])
  //     }
  //     else {
  //       notification.error({
  //         message: 'Error',
  //         description: "Failed to remove the user.",
  //       });
  //     }
  //     // console.log("user")
  //   }
  //   catch (error) {
  //     console.log(error)
  //     notification.error({
  //       message: 'Error',
  //       description: "Failed to remove the user.",
  //     });
  //   }
  // }



  const fetchParticipants = async ({ pageParam = 0 }) => {
    const endpoint = `/users/competition/getParticipants/${params.compId}`;
    try {
      const response = await makeRequest.get(endpoint, {
        params: { page: pageParam, size: 6 }, // Paginate results
      });
  
      // If the response status is 204, handle "no content" scenario
      if (response.status === 204) {
        console.log('No content received from server');
        return {
          data: [],
          hasNextPage: false,
          nextPageNo: undefined,
        };
      }
  
      // Otherwise, process the posts and pagination metadata
      return {
        data: response.data.details,  // Posts list
        hasNextPage: response.data.hasNextPage,  // More posts available?
        nextPageNo: response.data.nextPageNo,  // The next page number
      };
    } catch (error) {
      console.error('Fetch error:', error);
      throw error;
    }
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isError,
  } = useInfiniteQuery(
    ['cparticpants', currentUser.id], // Query key
    fetchParticipants, // Fetch function
    {
      getNextPageParam: (lastPage) => {
        // Return nextPageNo if there are more pages, otherwise return undefined
        return lastPage.hasNextPage ? lastPage.nextPageNo : undefined;
      },
      refetchOnWindowFocus: false, // Optional: prevent refetching on window focus
    }
  );

  const participants = data?.pages?.flatMap((page) => page.data) || [];


  // Detect scroll event
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop
        < document.documentElement.offsetHeight - 100 || // Trigger a bit before the bottom
        isFetching
      ) return;

      if (hasNextPage) {
        fetchNextPage();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [fetchNextPage, hasNextPage, isFetching]);


  const handleRedirect = (id) => {
    navigate(`/profile/${id}`)
  }

  return (
    <div className="members">
      <h1>Participants</h1>

      {isLoading && <h1 style={{color:"black"}}>Loading participants...</h1>}

      {isError && <h1 style={{color:"black"}}>Failed to load participants.</h1>}

      {!isLoading && !isError && participants.length === 0 && (
        <h1 style={{color:"black"}}>No participants in this competition</h1>
      )}

      {!isLoading && !isError && participants.length > 0 && (
        participants.map((participant) => (
          <div className="member" key={participant.userId}>
            <div className="right">
              <img
                src={participant.userProfileImagePath || "/default-avatar.png"}
                alt="profilePic"
              />
              <h3 onClick={() => handleRedirect(participant.uuid)}>
                {participant.userFirstName} {participant.userLastName}
              </h3>
            </div>
          </div>
        ))
      )}

      {/* Show a loading spinner when fetching next page */}
      {/* {isFetching && <h1 style={{color:"black"}}>Loading more participants...</h1>} */}
      {isFetching &&  <CircularProgress />}

      {/* {groupMembers.length === 0 ? (
        <div className="noMem">No members in this organisation</div>
      ) : (
        groupMembers.map((member) => (
          <div className="member" key={member.userId}>
            <div className="right">
              <img src={member.userProfileImagePath} alt="profilePic" />
              <h3 onClick={() => handleRedirect(member.userId)}>
                {member.userFirstName} {member.userlastName}
              </h3>
            </div>
            <div className="left">
              {currentUser.id === data.adminId && (
                <button onClick={() => removeUserFromGroupByAdmin(member.userId)} className="remove">
                  Remove from organisation
                </button>
              )}
            </div>
          </div>
        ))
      )} */}
    </div>
  )
}



