import React, { useContext, useEffect, useState } from 'react'
import './createGroups.scss'
import { AuthContext } from '../../context/authContext';
import { makeRequest } from '../../axios';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import AWS from 'aws-sdk'
import Image from "../../assets/img.png";


function CreateGroups() {

  const { currentUser } = useContext(AuthContext);

  const [list,setList] = useState();
  const [selectedUser, setSelectedUser] = useState([]);
  const [grpName, setGrpName] = useState('');
  const [grpDesc, setGrpDesc] = useState('');
  const [isCreatingGroup,setIsCreatingGroup] = useState(false)
  const [addMembers,setAddMembers] = useState(false)

  const navigate = useNavigate();

  const addToGroup = (userId)=>{
    setSelectedUser((prev)=>{
      if(prev.includes(userId)){
        return prev.filter((Id)=>Id!==userId)
      }
      else{
        return [...prev, userId]
      }
    })
  }

  const fetchFriends = async (id) => {
    const response = await makeRequest.get(`/users/friendrequest/getFriendsList/${id}`);
    // console.log(response.data.friendRequestData)

    let data = response.data.friendListData;
    let friends = data.filter((u)=>{
      return u.status === "APPROVED"
    })
    // console.log(friends)

    setList(friends)
    // return response.data.friendRequestData;
  };

  useEffect(()=>{
    fetchFriends(currentUser.UUID)
  },[])

  const createGroup = async () => {
    if (!grpName || !grpDesc) {                                           
      notification.error({
        message: 'Error',
        description: 'Please fill in all the fields before creating the group.',
      });
      return;
    }
  
    
    try {
      setIsCreatingGroup(true);
       const memberParams = selectedUser && selectedUser.length > 0 ? selectedUser.map(id => `&membersIds=${id}`).join("") : '&membersIds=0'
  
      const response = await makeRequest.post(
        `/users/group/createGroup?adminUserId=${currentUser.id}${memberParams}`,
        {
          "groupName": grpName,
          "groupDescription": grpDesc,
          "groupCoverProfileImagePath": null
        }
      );
  
      console.log(response.data.message);
      notification.success({
        message: 'Group created successfully',
        description: "You can also add other members to this group.",
      });
      navigate('/organisation');
    } catch (error) {
      console.error("Error creating group:", error);
      notification.error({
        message: 'Creation Failed',
        description: 'There was an error creating the group. Please try again.',
      });
    } finally {
      setIsCreatingGroup(false);
    }
  };
  
  

  return (
    <div className='createGroups'>
      <h1>Create a new Organisation</h1>
      <div className='formGroups'>
        <input className='inp' name='grpName' value={grpName} onChange={(e)=>setGrpName(e.target.value)} type="text" placeholder='Group Name' />
        <input className='inp' name='grpDesc' value={grpDesc} onChange={(e)=>setGrpDesc(e.target.value)} type="text" placeholder='What is this group about' />
          {
            !addMembers ? 
            <div style={{width:"100%",display:"flex",justifyContent:"end"}}>
              <button onClick={()=>setAddMembers(!addMembers)}>Add members</button>
            </div>
              :
            <div style={{width:"100%",display:"flex",justifyContent:"end"}}>
              <button onClick={()=>setAddMembers(!addMembers)}>Hide members</button>
            </div>
            }
          {
            addMembers &&
            <div className="list">
          {
            list.length > 0 ?
            list.map((item, index) => (
              <div key={index} className='friend'>
                <div style={{display:'flex',gap:'10px',justifyContent:'center',alignItems:'center'}}>
                <img src={item.userProfileImagePath} alt={item.userFirstName} width="30px" height="30px" style={{borderRadius:"50px"}} />
                <p>{item.userFirstName} {item.userLastName}</p>
                </div>
                <input type='checkbox' onClick={()=>addToGroup(item.userId)}/>
                {/* <button type='checkbox' onClick={()=>addToGroup(item)}>ADD</button> */}
              </div>
            ))
            :
            <p style={{textAlign:'center'}}>No friends right now,add friends firstly.</p>
          }
        </div>
          }
        <button onClick={createGroup}>{isCreatingGroup ? "Creating  organisation.." : "Create Organisation"}</button>
      </div>
    </div>
  )
}

export default CreateGroups
