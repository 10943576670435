import "./bottomNavBar.scss";
import { useState } from "react";
import { AuthContext } from "../../context/authContext";
import { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Drawer, Modal} from 'antd';
import { Navigate } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/Home";
import GroupIcon from '@mui/icons-material/Group';
import MessageIcon from '@mui/icons-material/Message';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import GroupsIcon from '@mui/icons-material/Groups';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import StoreIcon from '@mui/icons-material/Store';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import MenuIcon from '@mui/icons-material/Menu';
import { DarkModeContext } from "../../context/darkModeContext";
import CreateIcon from '@mui/icons-material/Create';
import home from '../../assets/house.png'
import friends from '../../assets/friendship.png'
import bookmark from '../../assets/bookmark.png'
import message from '../../assets/comments.png'
import groups from '../../assets/youth.png'
import pencil from '../../assets/pencil.png'
import calendar from '../../assets/calendar.png'
import logoutImg from '../../assets/logout.png'

const BottomNavBar = () => {
  const { currentUser , logout} = useContext(AuthContext);
  // console.log("currentUser is ",currentUser)
  const location = useLocation();
  const {darkMode} = useContext(DarkModeContext);
  const isMessagesPage = location.pathname === "/messages";
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate= useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState('right');
  const showDrawer = () => {
    setOpen(true);
  };
  const onChange = (e) => {
    setPlacement(e.target.value);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className={`bottomNavContainer ${isMessagesPage ? "messagePage" : ""}`}>
       <div className="menu">
      <div className="bottomNavitem">
          <Link to="/" className="item-link">
            {/* <HomeOutlinedIcon className="icon" fontSize="medium" sx={{ color: darkMode ? "#fff":"#000"}}/> */}
            <img src={home} width={"25px"}/>
          </Link>
        </div>
        <div className="bottomNavitem">
          <Link to="/connect" className="item-link">
            {/* <GroupIcon className="icon" fontSize="medium" sx={{ color: darkMode ? "#fff":"#000"}}/> */}
            <img src={friends} alt="" />
            {/* <span>Friends</span> */}
          </Link>
        </div>
        <div className="bottomNavitem">
          <Link to="/chat" className="item-link">
            {/* <MessageIcon className="icon" fontSize="medium" sx={{ color: darkMode ? "#fff":"#000"}}/> */}
            <img src={message} alt="" />
          </Link>
        </div>
        {/* <div className="bottomNavitem">
          <Link to="/groups" className="item-link">
          <img src={Groups} alt="" />
          </Link>
          </div> */}
        <div className="bottomNavitem">
          <Link to="/organisation" className="item-link">
            <img src={groups} alt="" />
          {/* <CameraAltIcon className="icon" fontSize="medium" sx={{ color: darkMode ? "#fff":"#000"}}/> */}
          </Link>
        </div>
        <div className="bottomNavitem">
        <Link onClick={showDrawer} className="item-link">
            {/* <img src={hamburger} alt="" /> */}
          <MenuIcon className="icon" fontSize="medium" sx={{ color: darkMode ? "#fff":"#000"}}/>
          </Link>
        </div>
      </div>
      <Drawer className="drawer-container"
        // title="Drawer with extra actions"
        placement={placement}
        width={500}
        onClose={onClose}
        open={open}
        // extra={
        //   <Space>
        //     <Button onClick={onClose}>Cancel</Button>
        //     <Button type="primary" onClick={onClose}>
        //       OK
        //     </Button>
        //   </Space>
        // }
      >
        <div className="userDetails">
          <Link to={`/profile/${currentUser.UUID}`} onClick={onClose} className="profile">
          <img src={currentUser.profilePic} alt="" width="100px" height="100px" />
          <div className="name">
          <h3>{currentUser.name}</h3>
          <h5>{currentUser.email}</h5>
          </div>
          </Link>
        </div>
        <hr className="hr" />
        <div className="options">
            <Link to="/saved" onClick={onClose}>
            {/* <BookmarkIcon className="icon" fontSize="medium" sx={{ color: "#000"}}/> */}
            <img src={bookmark} width={"25px"}/>
              <span>Saved</span>
            </Link>
            <Link to="/userFeeds"   onClick={onClose}>
            <img src={pencil} width={"25px"}/>
              <span >Social Feeds</span>
            </Link>
            <Link to="/events"   onClick={onClose}>
            <img src={calendar} width={"25px"}/>
              <span >Events</span>
            </Link>
            {/* <Link to="/courses"  onClick={onClose}>
            <DesktopMacIcon className="icon" fontSize="medium" sx={{ color: "#000"}}/>
              <span >Courses</span>
            </Link> */}
            {/* <Link to="/marketplace" onClick={onClose}>
            <StoreIcon className="icon" fontSize="medium" sx={{ color: "#000"}}/>
              <span >Marketplace</span>
            </Link> */}

            {/* <Link to="/collaborations"   onClick={onClose}>
            <HeadsetMicIcon className="icon" fontSize="medium" sx={{ color: "#000"}}/>
              <span >Collaborations</span>
            </Link>
            <Link to="/live"  onClick={onClose}>
            <LiveTvIcon className="icon" fontSize="medium" sx={{ color: "#000"}}/>
              <span >Live</span>
            </Link> */}
        </div>

        <button className="logout" onClick={() => setIsModalVisible(true)}>Logout</button>
        
      </Drawer>

      <Modal
        title="Logout Confirmation"
        visible={isModalVisible}
        onOk={handleLogout}
        onCancel={handleCancel}
        okButtonProps={{
          style: { backgroundColor: "#5271ff", color: "white" },
        }}
        footer={[<button style={{backgroundColor:"#e4002b",color:"white",border:"none",borderRadius:"5px", width: "4rem",height: "2rem",fontWeight: "600",fontSize: "0.9rem"}} key="logout" type="primary" onClick={handleLogout}>
          Logout
        </button>]}
      >
        <p>Are you sure you want to logout?</p>
      </Modal>
    </div>
  );
};

export default BottomNavBar;
