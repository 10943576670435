import React, { useContext, useState } from "react";
import Post from "../../components/post/Post";
import AboutPage from "./AboutPage";
import FriendsListing from ".././friendsListing/FriendsListng"
// import "./profileTabs.scss"
import './tabs.scss';
import { AuthContext } from "../../context/authContext";
import { makeRequest } from "../../axios";
import { notification } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import AWS from "aws-sdk";
import heic2any from "heic2any";
import SendIcon from "@mui/icons-material/Send";
import { DarkModeContext } from "../../context/darkModeContext";
import { Link, useParams } from "react-router-dom";
import Image from "../../assets/img.png";
import { useQuery } from "@tanstack/react-query";
import { queryAllByAltText } from "@testing-library/react";
import imageCompression from "browser-image-compression";

const awsRegion = process.env.REACT_APP_AWS_REGION;
const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;

const ProfileTabsPages = ({ userId, selectedTab ,data,post,pageMembers}) => {
  // console.log(data);

  return (
    <div className="profileTabs">
    
      <div className="tabContent">
        {selectedTab === "Posts" && <PagesPost post={post} data={data} />}
        {selectedTab === "Aboutpage" && <AboutPage data={data}/>}
        {selectedTab === "Friends" && <MembersPages userId={userId} pageMembers={pageMembers} data={data}/>}
      </div>
    </div>
  );
};

export default ProfileTabsPages;


export const PagesPost = ({post,data})=>{
  const {currentUser} = useContext(AuthContext)
  const {darkmode} = useContext(DarkModeContext)
  const [file, setFile] = useState(null);
  const [desc, setDesc] = useState("");
  const S3_BUCKET = "streesakti";
  const [inputNameError, setInputNameError] = useState("");

  AWS.config.update({
    accessKeyId: awsAccessKeyId,
    secretAccessKey: awsSecretAccessKey,
    region: awsRegion,
  });

  const s3 = new AWS.S3();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (newPost) => {
      return makeRequest.post(
        `/users/pages/addPost/${currentUser.id}/${data.pagesId}`,
        newPost,
        {
          headers: {
            "Content-Type": "multipart/form-data",  // Manually setting the Content-Type header
          },
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["pageDetails"]);
        notification.success({
          message: "Post uploaded successfully",
          description: "Your post has been shared with everyone.",
        });
      },
    }
  );

  const handleInput = (e) => {
    setDesc(e.target.value);
    if (e.target.value.length > 0) {
      setInputNameError("");
    }
  };


  // const handleClick = async () => {
  //   if(!desc && !file){
  //     notification.error({
  //       message: 'No Image or No Description',
  //       description: 'You have to add an image or a description.',
  //     });
  //     return;
  //   }
  //   let fileToUpload = file;
  //   if (file && file.type === "image/heic") {
  //     try {
  //       const convertedBlob = await heic2any({
  //         blob: file,
  //         toType: "image/jpeg",
  //       });
  //       fileToUpload = new File(
  //         [convertedBlob],
  //         file.name.replace(/\.[^/.]+$/, ".jpg"),
  //         { type: "image/jpeg" }
  //       );
  //     } catch (error) {
  //       console.error("Error converting HEIC to JPEG:", error);
  //       notification.error({
  //         message: "Conversion Failed",
  //         description: `There was an error converting your HEIC image: ${error.message}`,
  //       });
  //       return;
  //     }
  //   }

  //   let postImageUrl = null;
  //   if (file) {
  //     const params = {
  //       Bucket: S3_BUCKET,
  //       Key: `posts/${fileToUpload.name}`,
  //       Body: fileToUpload,
  //     };

  //     try {
  //       const data = await s3.upload(params).promise();
  //       postImageUrl = data.Location;
  //     } catch (error) {
  //       console.error("Error uploading file:", error);
  //       notification.error({
  //         message: "Upload Failed",
  //         description: `There was an error uploading your file: ${error.message}`,
  //       });
  //       return;
  //     }
  //   }

  //   mutation.mutate({
  //     postName: desc,
  //     postImageUrl: postImageUrl,
  //     postType: file ? fileToUpload.type : null,
  //   });

  //   setFile(null);
  //   setDesc("");
  // };


  const handleClick = async () => {
    if (!desc.trim() && !file) {
      notification.error({
        message: "No Image or No Description",
        description: "You have to add an image or a description.",
      });
      return;
    }
  
  
    let fileToUpload = file;
  
    if (file) {
      try {
        // Handle HEIC to JPEG conversion
        if (file.type === "image/heic") {
          const convertedBlob = await heic2any({
            blob: file,
            toType: "image/jpeg",
          });
          fileToUpload = new File(
            [convertedBlob],
            file.name.replace(/\.[^/.]+$/, ".jpg"),
            { type: "image/jpeg" }
          );
        }
  
        // Compress image if it's an image file
        if (fileToUpload.type.startsWith("image")) {
          const imageOptions = {
            maxSizeMB: 0.1, // 150KB
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          };
          const compressedBlob = await imageCompression(fileToUpload, imageOptions);
          fileToUpload = new File([compressedBlob], file.name, {
            type: compressedBlob.type,
            lastModified: file.lastModified,
          });
        } 
        
        // Check video size if it's a video file
        else if (fileToUpload.type.includes("video")) {
          const maxVideoSizeMB = 10;
          const videoSizeMB = file.size / 1024 / 1024;
          if (videoSizeMB > maxVideoSizeMB) {
            notification.error({
              message: 'Video Too Large',
              description: `The video size is ${videoSizeMB.toFixed(2)} MB, which exceeds the 10MB limit.`,
            });
            return;
          }
        }
  
        // Upload the file
        const formData = new FormData();
        formData.append("postName", desc);
        formData.append("postImage", fileToUpload);
        formData.append("postType", fileToUpload.type);
  
        // console.log("FormData:", formData.get("postImage"));
  
        mutation.mutate(formData);
      } catch (error) {
        console.log("Error during processing or upload:", error);
        notification.error({
          message: "Upload Failed",
          description: `There was an error: ${error.message}`,
        });
      } finally {
        setFile(null);
        setDesc("");
      }
    }else{
      if(desc){
        try {
          const formData = new FormData();
          formData.append("postName", desc);   
    
          mutation.mutate(formData);          
        } catch (error) {
          notification.error({
            message: "Update Failed",
            description: `There was an error: ${error.message}`,
          });
        }finally {
          setFile(null);
          setDesc("");
        }
      }
    }
  };


  return(
    <div className="postPages">
    {
      currentUser.id === data.adminId &&
     <div className="shareGrp" style={{width:"80%",marginRight:"auto",marginLeft:'auto'}}>
              <div className="container">
                <div className="top">
                  <div className="left">
                    <img src={currentUser.profilePic} alt="" />
                    <textarea
                      rows={"3"}
                      type="text"
                      placeholder={`What's on your mind ${currentUser.name}?`}
                      onChange={handleInput}
                      value={desc}
                    />
                  </div>
                  <div className="right">
                    {file && file.type.startsWith("image/") && (
                      <img className="file" alt="" src={URL.createObjectURL(file)} />
                    )}
                    {file && file.type.startsWith("video/") && (
                      <video className="file" controls>
                        <source src={URL.createObjectURL(file)} type={file.type} />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    {!file && <p>No file selected</p>}
                  </div>
                </div>
                {inputNameError && <span className="inputError">{inputNameError}</span>}
                <hr />
                <div className="bottom">
                  <div className="left">
                    <input
                      type="file"
                      id="file"
                      accept="image/*,video/*"
                      style={{ display: "none" }}
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                    <label htmlFor="file">
                      <div className="item">
                        <img src={Image} alt="" />
                        <span>Add Image/Video</span>
                      </div>
                    </label>
                  </div>
                  <div className="rightGrp">
                    <button onClick={handleClick}>
                      Share
                      <SendIcon style={{ fontSize: "1.3rem" }} sx={{ color: darkmode ? "#000" : "#fff" }} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
    }
    {
      post.length === 0 ?
      <p className="noPostPages" style={{color:"gray"}}>No posts found.</p>
      :
      post.map((post)=>{
        return(
          <Post post={post} key={post.postId}/>
        )
      })
    }
    </div>
  )
}

export const MembersPages=({pageMembers,data})=>{
  const {currentUser} = useContext(AuthContext)
  const params = useParams();
  const queryClient = useQueryClient();
  const removeUsersFollowPage=async(id)=>{
    try {
      const res = await makeRequest.delete(`/users/pages/exitPage/${currentUser.id}/${params.id}/${id}`)
      if(res.status === 200){
        notification.success({
          message: "User removed successfully",
          description: "You have successfully removed the user's following.",
        });
        queryClient.invalidateQueries(["pageDetails"]);
      }
      else{
        notification.error({
          message: "Users remove Failed",
          description: "There was an error removing the user from the page.",
        });
      }
    } catch (error) {
      console.error("Error unfollowing page:", error);
      notification.error({
        message: "Users remove Failed",
        description: "There was an error removing the user from the page.",
      });
    }
  }

  return(
    <div className="members">
    <h1>Followers</h1>
    {pageMembers.length === 0 ? (
      <div className="noFollowers">No followers for this page.</div>
    ) : (
      pageMembers.map((member) => (
        <div className="member" key={member.userId}>
          <div className="right">
            <img src={member.userProfileImagePath} alt="profilePic" />
            <Link to={`/profile/${member.userId}`}>{member.userFirstName} {member.userlastName}</Link>
          </div>
          <div className="left">
            {currentUser.id === data.adminId && (
              <button onClick={() => removeUsersFollowPage(member.userId)} className="remove">
                Remove user
              </button>
            )}
          </div>
        </div>
      ))
    )}
  </div>  
  )
}