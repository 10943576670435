import React, { useEffect, useRef, useState } from 'react';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const apiUrl = process.env.REACT_APP_API_URL;

const ChatWindow = ({ setSelectedFriend, selectedFriend, currentUser }) => {
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState("");
    const [isConnected, setIsConnected] = useState(false);
    const stompClientRef = useRef(null);
    const messagesEndRef = useRef(null);

    useEffect(() => {
        if (!currentUser || !selectedFriend) return;
        setIsConnected(false);
        const socket = new SockJS(apiUrl + '/ws');
        const stompClient = Stomp.over(socket);
        stompClientRef.current = stompClient;

        let subscription;

        const attemptConnection = () => {
            stompClient.connect({}, () => {
                console.log('Connected try');
                setIsConnected(true);

                const senderId = currentUser.id;
                const receiverId = selectedFriend.userId;

                subscription = stompClient.subscribe(`/user/${senderId}/queue/messages`, (message) => {
                    const parsedMessage = JSON.parse(message.body);
                    console.log("messages received", parsedMessage)
                    showMessage(parsedMessage);
                });
            }, (error) => {
                console.error('Error connecting:', error);
                console.log('Error details:', error.headers, error.body);
                setIsConnected(false);

                // Retry connection after 5 seconds if failed
                setTimeout(attemptConnection, 5000);
            });
        };

        attemptConnection();

        const loadChatHistory = () => {
            const senderId = currentUser.id;
            const receiverId = selectedFriend.userId;
            console.log('Loading chat history');
            if (senderId && receiverId) {
                fetch(apiUrl + `/users/chatmessage/history/${senderId}/${receiverId}`)
                    .then(response => response.json())
                    .then(messages => {
                        console.log("Loaded message history:", messages);
                        setMessages(messages);
                    })
                    .catch(error => console.error('Error fetching chat history:', error));
            }
        };

        const showMessage = (message) => {
            setMessages(prevMessages => [...prevMessages, message]);
        };

        loadChatHistory();

        return async () => {
            try {

                if (subscription) {
                    subscription.unsubscribe();
                }
                if (stompClientRef.current && isConnected) {
                    stompClientRef.current.disconnect();
                }
            } catch (error) {
                console.log(error);
                setIsConnected(false)
            }
        };
    }, [currentUser, selectedFriend]);

    const handleInputChange = (e) => {
        setInputMessage(e.target.value);
    };

    const handleSendMessage = () => {
        if (inputMessage.trim() !== "" && isConnected) {
            const senderId = currentUser.id;
            const receiverId = selectedFriend.userId;

            if (stompClientRef.current) {
                const message = {
                    sender: { userId: senderId },
                    receiver: { userId: receiverId },
                    content: inputMessage
                };

                stompClientRef.current.send('/app/chat', {}, JSON.stringify(message));
                setInputMessage("");
            }
        }
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <>
            <div className="chat-messages">
                <div className="nameContainer">
                    <ArrowBackIcon onClick={() => setSelectedFriend(null)} />
                    <div>{selectedFriend.userFirstName + " " + selectedFriend.userLastName}</div>
                </div>
                {messages.map((message, index) => (
                    <div key={index} className={`message ${message.sender.userId === currentUser.id ? "me" : "friend"}`}>
                        <span className={`${message.sender.userId === currentUser.id ? "me" : "friend"}`}> {message.content} </span>
                    </div>
                ))}
                <div ref={messagesEndRef}>

                </div>
            </div>
            <div className="input-container">
                <input
                    type="text"
                    value={inputMessage}
                    onChange={handleInputChange}
                    placeholder={isConnected ? "Type Your Message" : "Connecting...."}
                    disabled={!isConnected}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            handleSendMessage();
                        }
                    }}
                />
                <button onClick={handleSendMessage}>Send</button>
            </div>
        </>
    );
};

export default ChatWindow;
